.at-home-learning-container {
  background-color: #faf5ed;
  position: relative;
  padding: 51px 0;
}
.at-home-learning-container .section-header {
  max-width: 800px;
  font-size: 60px;
  line-height: 64px;
  color: #00c4c9;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 999px) {
  .at-home-learning-container .section-header {
    font-size: 32px;
    line-height: 40px;
  }
}
.at-home-learning-container .sub-header {
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 25px;
}
@media (max-width: 999px) {
  .at-home-learning-container .sub-header {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
  }
}
.at-home-learning-container .at-home-learning-features {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 999px) {
  .at-home-learning-container .at-home-learning-features {
    flex-direction: column;
    align-items: center;
  }
}
.at-home-learning-container .at-home-learning-features .feature {
  flex: 1;
  max-width: 320px;
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #fff;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07), 0 1px 1px rgba(0, 0, 0, 0.09), 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 5px;
}
@media (max-width: 999px) {
  .at-home-learning-container .at-home-learning-features .feature {
    margin-bottom: 20px;
  }
}
.at-home-learning-container .at-home-learning-features .feature .header {
  font-size: 22px;
  line-height: 38px;
  margin: 18px 0 15px;
}
.at-home-learning-container .at-home-learning-features .feature .image {
  height: 61px;
}
.at-home-learning-container .at-home-learning-features .feature .description {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  padding: 10px;
}
.at-home-learning-container .at-home-learning-features .feature .action a {
  padding: 11px 20px;
  max-width: 300px;
  width: 95%;
  font-size: 15px;
  margin-bottom: 10px;
}
@media (max-width: 999px) {
  .at-home-learning-container .at-home-learning-features .feature .action a {
    max-width: 260px;
    width: 100%;
  }
}
